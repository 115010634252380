import { Card, TextField, Button, styled, alpha } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: theme.shadows[1],
  },
}));

export const ListingImage = styled('img')({
  width: 96,
  height: 80,
  objectFit: 'cover',
  borderRadius: 4,
});

export const EditButton = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'none',
  padding: '6px 16px',
  color: theme.palette.grey[700],
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[400],
  },
}));

export const SearchField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.grey[700],
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[400],
    },
  },
}));
