import { httpClient } from 'app/api/http/http-client';
import { ActivityFilter, ActivityLog, Client, Property, PropertyStatus } from './_models';
import defaultImage from '../../../../../assets/default-image.png';
import { Response } from '../../../../constants/models';

const getMyListings = (): Promise<Property[]> => {
  return httpClient.get('/Property');
};

const getMyListingsFilter = (query: string): Promise<Response<Property[]>> => {
  return httpClient.get(`/Property/list?${query}`);
};

const getActivities = (filter: ActivityFilter): Promise<Response<ActivityLog[]>> => {
  const queryParams = {
    Status: filter.status,
    PageSize: filter.pageSize,
    PageNumber: filter.pageNumber,
    SearchFilter: filter.searchFilter?.trim(),
  };

  return httpClient.get('/Activities', {
    params: queryParams,
  });
};

const getActivityPendingCount = (): Promise<number> => {
  return httpClient.get('/Activities/pending/count');
};

const acceptActivity = (id: string): Promise<void> => {
  return httpClient.patch(`/Activities/${id}/accept`);
};

const declineActivity = (id: string): Promise<void> => {
  return httpClient.patch(`/Activities/${id}/decline`);
};

const getActivityClient = (id: string): Promise<Client> => {
  return httpClient.get(`/Activities/client/${id}`);
};

const getImageUrl = (image: string) => {
  return image?.length > 0 ? `https://test.urexc.com/f/${image}` : defaultImage;
};

const updatePropertyStatus = (id: string, status: PropertyStatus): Promise<void> => {
  return httpClient.patch(`/Property/${id}/status`, null, {params: { status }});
};

export {
  getMyListings,
  getActivities,
  getActivityPendingCount,
  acceptActivity,
  declineActivity,
  getActivityClient,
  getImageUrl,
  getMyListingsFilter,
  updatePropertyStatus,
};
