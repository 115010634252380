/* eslint-disable curly */
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';
import {
  useAddListingDraft,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllProperties } from 'app/utils/services/propertyService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import { residentialSchema } from 'app/pages/AddListing/MarketingDescription/schema';

const useMarketingDescription = () => {
  const [focused, setFocused] = useState(false);
  const [closingDate, setClosingDate] = useState(null);
  const [closingResDate, setClosingResDate] = useState('');
  const [checked, setChecked] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width: 500px)');
  const isMobile = useMediaQuery('(max-width: 992px)');

  const [openDesc, setOpenDesc] = useState(true);
  const [openPrice, setOpenPrice] = useState(true);
  const [openBrochure, setOpenBrochure] = useState(true);

  const [file, setFile] = useState(null);
  const [fileFromBack, setFileFromBack] = useState();
  const [brochureError, setBrochureError] = useState(false);

  const onDrop = useCallback((acceptedFiles: any, fileRejection: any) => {
    if (fileRejection[0]?.errors[0]?.code === 'file-too-large') {
      setBrochureError(true);
      setFile(null);
    } else {
      setFileFromBack(undefined);
      setFile(acceptedFiles);
      setBrochureError(false);
    }
  }, []);

  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const {
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(residentialSchema) });

  const { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError } = useGetAddListingData(
    role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
    sessionId,
    {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  );
  const { isLoadingAllProperties, dataAllProperties } = useGetAllProperties(
    role === 'residential' ? 2 : role === 'commercial' && 1
  );

  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();

  useEffect(() => {
    if (!isMobile && !openDesc) {
      setOpenDesc(true);
    }
    if (!isMobile && !openPrice) {
      setOpenPrice(true);
    }
    if (!isMobile && !openBrochure) {
      setOpenBrochure(true);
    }
  }, [isMobile]);

  useEffect(() => {
    setValue('unpriced', checked);
  }, []);

  useEffect(() => {
    if (
      sessionId !== null &&
      dataGetAddListingData !== undefined &&
      dataGetAddListingData?.marketing !== null &&
      !isLoadingAllProperties &&
      !isLoadingGetAddListingData
    ) {
      setValue('marketingDesc', dataGetAddListingData?.marketing.marketingDescription || '');
      setValue('price', dataGetAddListingData?.marketing.askingPrice || '');
      setValue('minPrice', dataGetAddListingData?.marketing.unpricedMin || '');
      setValue('maxPrice', dataGetAddListingData?.marketing.unpricedMax || '');
      setValue('dueDeligence', dataGetAddListingData?.marketing.dueDeligence || '');
      setValue('inspectionDate', dataGetAddListingData?.marketing.inspectionPeriodByDay || '');
      setValue('financialAccepted', dataGetAddListingData?.marketing.financialAccepted);
      setValue('unpriced', dataGetAddListingData?.marketing.unpriced || false);
      setFileFromBack(
        dataGetAddListingData?.marketing?.brochure?.substring(
          +dataGetAddListingData?.marketing?.brochure?.indexOf('.') + 1
        )
      );
      setClosingResDate(dataGetAddListingData?.marketing.closingDate || '');
      setChecked(dataGetAddListingData?.marketing.unpriced);
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData, isLoadingAllProperties, isLoadingGetAddListingData]);

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/investment-details`);
    }
  }, [dataDraft, dataContinue]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const handleClosingDate = (closingDate_: any) => {
    setClosingDate(closingDate_);
    setClosingResDate(format(closingDate_, 'MM-dd-yyyy'));
  };

  const onFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';

    const residentialFormData = new FormData();
    residentialFormData.append('Marketing.Description', data.marketingDesc);
    residentialFormData.append('Marketing.AskingPrice', !checked ? data.price : 0);
    residentialFormData.append('Marketing.InspectionPeriodByDay', data.inspectionDate);
    residentialFormData.append('Marketing.ClosingDate', closingResDate);
    residentialFormData.append('Marketing.DueDeligence', data.dueDeligence);
    residentialFormData.append('Marketing.Unpriced', data.unpriced);
    residentialFormData.append('Marketing.UnpricedMin', checked ? data.minPrice : 0);
    residentialFormData.append('Marketing.UnpricedMax', checked ? data.maxPrice : 0);
    residentialFormData.append('Marketing.Brochure', file === null ? '' : file[0]);
    if (getValues('financialAccepted') !== undefined) {
      for (const row of getValues('financialAccepted')) {
        if (row.id !== undefined) {
          residentialFormData.append('Marketing.FinancialAccepted', row.id);
        } else {
          residentialFormData.append('Marketing.FinancialAccepted', row);
        }
      }
    }
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => submitForm(data, event));
  const handleClearFile = useCallback(() => {
    setFile(null);
    setFileFromBack(undefined);
    setBrochureError(false);
  }, []);

  return {
    control,
    register,
    setValue,
    focused,
    onFocus,
    closingDate,
    handleClosingDate,
    handleBlur,
    closingResDate,
    dataAllProperties,
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    isLoadingGetAddListingData,
    dataGetAddListingData,
    checked,
    setChecked,
    isMobile,
    isMobilePhone,
    file,
    onDrop,
    handleClick,
    openBrochure,
    setOpenBrochure,
    openDesc,
    setOpenDesc,
    openPrice,
    setOpenPrice,
    fileFromBack,
    onSubmit,
    getValues,
    errors,
    brochureError,
    handleClearFile,
  };
};

export default useMarketingDescription;
