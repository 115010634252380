import React, { useEffect, useState } from 'react';
import { Button, Drawer, Box, Typography, IconButton, Avatar, Stack, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetails } from '../../../api/account-settings/models';
import { drawerBody } from './details-style.sx';
import AccountSettingEditIcon from '../../../components/SvgIcons/AccountSettingEdit';
import DeleteTrashIcon from '../../../components/SvgIcons/DeleteTrashIcon';
import { useMutation, useQueryClient } from 'react-query';
import { updateUserDetails } from '../../../api/account-settings/requests';
import toast from 'react-hot-toast';
import logger from '../../../utils/logger';
import AvatarUploader from './avatar-logo-uploader';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userDetails: UserDetails;
  onPasswdChange?: () => void;
}

const UpdateUserDrawer = ({ isOpen, onClose, userDetails, onPasswdChange }: Props) => {
  const [formData, setFormData] = useState<UserDetails>(userDetails);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(updateUserDetails, {
    onSuccess: () => {
      toast.success('User details updated successfully');
      queryClient.invalidateQueries('userDetails');
      onClose();
    },
    onError: (error: any) => {
      logger.error('Error updating user details:', error);
      toast.error('Failed to update user details. Please try again.');
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = () => {
    logger.log('Updating user details:', formData);
    mutation.mutate(formData);
  };

  const handleAvatarSave = (newAvatar: string) => {
    setFormData({ ...formData, logo: newAvatar });
  };

  useEffect(() => {
    setIsDirty(JSON.stringify(formData) !== JSON.stringify(userDetails));
  }, [formData]);

  return (
    <Drawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
      <Box sx={drawerBody}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h6">Account Settings</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Avatar Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          {!isEditingAvatar ? (
            <>
              <Avatar src={formData.logo} sx={{ width: 80, height: 80, mr: 2 }} />
              <Box sx={{ ml: 2 }}>
                <Button variant="outlined" size="small" sx={{ mr: 1 }} onClick={() => setIsEditingAvatar(true)}>
                  Edit
                </Button>
                <Button variant="text" size="small" startIcon={<DeleteTrashIcon />}>
                  Remove
                </Button>
              </Box>
            </>
          ) : (
            <AvatarUploader
              currentAvatar={formData.logo}
              onSave={handleAvatarSave}
              onCancel={() => setIsEditingAvatar(false)}
            />
          )}
        </Box>
        {/* Form Fields */}
        <Stack spacing={3} sx={{ flex: 1 }}>
          <TextField
            name="name"
            label="Name"
            defaultValue={formData.name}
            variant="outlined"
            disabled
            // onChange={handleInputChange}
          />

          <TextField
            label="Lastname"
            name="lastName"
            defaultValue={formData.lastName}
            variant="outlined"
            onChange={handleInputChange}
          />
          <TextField
            name="email"
            label="Email Address"
            defaultValue={formData.email}
            type="email"
            variant="outlined"
            onChange={handleInputChange}
          />

          <TextField
            name="phoneNumber"
            label="Phone Number"
            defaultValue={formData.phoneNumber}
            variant="outlined"
            onChange={handleInputChange}
          />

          <TextField
            label="Password"
            type="password"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    variant="text"
                    onClick={onPasswdChange}
                    startIcon={<AccountSettingEditIcon />}
                    sx={{
                      color: 'text.secondary',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: 'grey.100',
                      },
                    }}
                  >
                    Change
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {/* Update Button */}
        <Box sx={{ mt: 4 }}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            disabled={mutation.isLoading || isEditingAvatar || !isDirty}
            onClick={handleUpdate}
            sx={{
              bgcolor: 'grey.300',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.400',
              },
              py: 1.5,
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default UpdateUserDrawer;
