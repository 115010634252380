import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, CardContent, debounce, Stack, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Image } from 'react-bootstrap';
import { DetailButton, StyledCard } from './components/lead-activity-page.sx';
import { SearchField } from '../listings/components/ny-listings.sx';
import Details from './components/Details';
import { ActivityFilter, ActivityLog, ActivityTypeStr } from '../listings/core/_models';
import { getActivities, getImageUrl } from '../listings/core/_requests';

// Import icons and map them
import ViewedPropertyIcon from '../../../components/Icons/ViewedPropertyIcon';
import SavedPropertyIcon from '../../../components/Icons/SavedPropertyIcon';
import TelephoneIcon from '../../../components/Icons/TelephoneIcon';
import FlyerIcon from '../../../components/Icons/FlyerIcon';
import InformationIcon from '../../../components/Icons/InformationIcon';
import PrinterIcon from '../../../components/Icons/PrinterIcon';
import RequestDocumentIcon from '../../../components/Icons/RequestDocumentIcon';
import ViewDocumentIcon from '../../../components/Icons/ViewDocumentIcon';

const activityTypeMap = {
  [ActivityTypeStr.VIEW_PROPERTY]: {
    icon: <ViewedPropertyIcon />,
    description: 'Viewed property',
  },
  [ActivityTypeStr.VIEW_DOCUMENT]: {
    icon: <ViewDocumentIcon />,
    description: 'Viewed document',
  },
  [ActivityTypeStr.REQUEST_DOCUMENT]: {
    icon: <RequestDocumentIcon />,
    description: 'Requested document',
  },
  [ActivityTypeStr.OPEN_FLYER]: {
    icon: <FlyerIcon />,
    description: 'Opened flyer',
  },
  [ActivityTypeStr.PRINT_PAGE]: {
    icon: <PrinterIcon />,
    description: 'Printed page',
  },
  [ActivityTypeStr.CLICK_PHONE_NUMBER]: {
    icon: <TelephoneIcon />,
    description: 'Clicked phone number',
  },
  [ActivityTypeStr.SAVED_PROPERTY]: {
    icon: <SavedPropertyIcon />,
    description: 'Saved property',
  },
  [ActivityTypeStr.INFORMATION_REQUEST]: {
    icon: <InformationIcon />,
    description: 'Requested information',
  },
};

const LeadActivity = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [filter, setFilter] = useState<ActivityFilter>({
    pageNumber: 0,
    pageSize: 15,
    searchFilter: '',
  });

  // Create debounced search function
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setFilter((prev) => ({
        ...prev,
        searchFilter: query,
        pageNumber: 0, // Reset to first page when searching
      }));
    }, 500),
    []
  );

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const {
    data: activities,
    isLoading,
    isError,
  } = useQuery(['activities', filter], () => getActivities(filter), {
    keepPreviousData: true,
  });

  // Cleanup debounce on component unmount
  React.useEffect(() => {
    return () => {
      debouncedSearch.clear();
    };
  }, [debouncedSearch]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          Error fetching activities
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <SearchField
            fullWidth
            size="small"
            placeholder="Search My Listings"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <Search sx={{ color: 'grey.600', mr: 1 }} />,
            }}
          />
        </Box>
      </Box>

      <Typography variant="h6" sx={{ mb: 3 }}>
        Activity feed
      </Typography>

      <Stack spacing={2}>
        {activities?.values.length === 0 ? (
          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', py: 4 }}>
            No activities found
          </Typography>
        ) : (
          activities?.values.map((activity: ActivityLog) => {
            const activityType = activityTypeMap[activity.type];
            return (
              <StyledCard key={activity.id} sx={{ border: '1px solid #ddd', borderRadius: '12px' }}>
                <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Image
                        src={getImageUrl(activity.propertyInfo.imageUrl)}
                        alt={activity.propertyInfo.name}
                        style={{
                          width: '120px',
                          height: '80px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                          {activity.propertyInfo.name}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {activityType.icon}
                          <Typography variant="body2" color="text.secondary">
                            {activityType.description}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          maxWidth: 250,
                          textAlign: 'right',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {activity.userInfo.fullName}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                        {new Date(activity.createdTime).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <DetailButton variant="contained" size="small" onClick={() => setIsDetailOpen(true)}>
                      Detail
                    </DetailButton>
                  </Stack>
                </CardContent>
                {isDetailOpen && (
                  <Details userInfo={activity.userInfo} isOpen={isDetailOpen} onClose={() => setIsDetailOpen(false)} />
                )}
              </StyledCard>
            );
          })
        )}
      </Stack>
    </Box>
  );
};

export default LeadActivity;
