export const ROUTES = {
  root: '/',
  login: '/login',
  registration: '/registration',
  forgotPass: '/reset-password',
  addListingPropertyInfo: '/add-listing/:role/property-information',
  editingListingPropertyInfo: '/add-listing/:role/property-information/:id',
  addListingMarketingDesc: '/add-listing/:role/marketing-description',
  addListingInvestmentDetails: '/add-listing/:role/investment-details',
  addListingGallery: '/add-listing/:role/gallery',
  addListingConfidentiality: '/add-listing/:role/confidentiality',
  addListingPersonDetails: '/add-listing/:role/person-details',
  addListingPreview: '/add-listing/:role/preview',
  dashboard: {
    contacts: '/dashboard/contacts',
    reminders: '/dashboard/reminders',
    myProperties: '/dashboard/myproperties',
    dealPiplineDetail: '/dashboard/deal-pipeline/detail/:id',
    dealPipline: '/dashboard/deal-pipeline',
    listings: '/dashboard/listings',
    listingsRequest: '/dashboard/listings/requests',
    leadActivity: '/dashboard/lead-activity',
    accountSettings: '/dashboard/account-settings',
    notificationSettings: '/dashboard/notifications-settings',
    exports: '/dashboard/exports',
  },
};
