import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { black2 } from 'app/components/ThemeProvider/colors';
import CustomInput from 'app/components/CustomInput';
import useMarketingDescription from './useMarketingDescription';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CustomSelect from 'app/components/CustomSelect';
import CustomFileUpload from 'app/components/CustomFileUpload';
import {
  btnSectionBox,
  container,
  draftBtn,
  halfRowContainer,
  loaderBox,
  rowContainer,
  saveBtn,
  sectionBox,
} from './MarketingDescription.sx';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const MarketingDescription = () => {
  const {
    control,
    register,
    focused,
    onFocus,
    handleClosingDate,
    closingResDate,
    closingDate,
    handleBlur,
    dataAllProperties,
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    isLoadingGetAddListingData,
    setChecked,
    checked,
    isMobilePhone,
    isMobile,
    onDrop,
    file,
    handleClick,
    setOpenPrice,
    openPrice,
    setOpenBrochure,
    openBrochure,
    openDesc,
    setOpenDesc,
    fileFromBack,
    onSubmit,
    getValues,
    errors,
    brochureError,
    handleClearFile,
  } = useMarketingDescription();

  if (isLoadingAllProperties) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  if (isLoadingGetAddListingData) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} data-isdraft="false">
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDesc, openDesc)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>
              Marketing/Property Description
            </Typography>
            {isMobile && (openDesc ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openDesc}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <TextField
              multiline={true}
              type={'text'}
              error={errors.marketingDesc !== undefined}
              {...register('marketingDesc')}
              sx={{
                width: '100%',
                height: '243px',
                '& .MuiInputBase-root ': {
                  height: '243px !important',
                  '& textarea': {
                    height: '243px !important',
                    padding: '12px 0',
                  },
                },
              }}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenPrice, openPrice)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Price</Typography>
            {isMobile && (openPrice ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openPrice}>
            <Box
              sx={{
                mb: '11px',
                '@media screen and (max-width: 500px)': {
                  mb: 0,
                },
              }}
            >
              <Box sx={rowContainer}>
                {checked ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '25px',
                      width: '100%',
                      '@media screen and (max-width: 500px)': {
                        flexDirection: 'column',
                        gap: 0,
                      },
                    }}
                  >
                    <CustomInput
                      id={'min-price'}
                      label={'Min Price'}
                      type={'text'}
                      error={errors.minPrice}
                      rest={{
                        ...register('minPrice', {
                          value: getValues('minPrice'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'max-price'}
                      label={'Max Price'}
                      type={'text'}
                      error={errors.maxPrice}
                      rest={{
                        ...register('maxPrice', {
                          value: getValues('maxPrice'),
                        }),
                      }}
                    />
                  </Box>
                ) : (
                  <CustomInput
                    id={'price'}
                    label={'Price'}
                    type={'text'}
                    error={errors.price}
                    rest={{
                      ...register('price', {
                        value: getValues('price'),
                      }),
                    }}
                  />
                )}
                {isMobilePhone && (
                  <FormControlLabel
                    control={<Checkbox checked={checked} />}
                    label="Unpriced"
                    sx={{
                      m: 0,
                      display: 'flex',
                      gap: '12px',
                      width: 'fit-content',
                      mb: '11px',
                      '& .MuiButtonBase-root': { padding: 0 },
                    }}
                    {...register('unpriced', { value: checked, onChange: () => setChecked(!checked) })}
                  />
                )}
                <CustomInput
                  id={'due-diligence'}
                  label={'Due Diligence'}
                  type={'text'}
                  error={errors.dueDeligence}
                  rest={{
                    ...register('dueDeligence', {
                      value: getValues('dueDeligence') === undefined ? '' : getValues('dueDeligence'),
                    }),
                  }}
                />
              </Box>
              {!isMobilePhone && (
                <FormControlLabel
                  control={<Checkbox checked={checked} />}
                  label="Unpriced"
                  sx={{
                    m: 0,
                    display: 'flex',
                    gap: '12px',
                    width: 'fit-content',
                    '& .MuiButtonBase-root': { padding: 0 },
                  }}
                  {...register('unpriced', { value: checked, onChange: () => setChecked(!checked) })}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'inspection-date'}
                label={'Inspection Date'}
                type={'text'}
                error={errors.inspectionDate}
                rest={{
                  ...register('inspectionDate', {
                    value: getValues('inspectionDate') === undefined ? '' : getValues('inspectionDate'),
                  }),
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  bottom: '25px',
                  '@media screen and (max-width: 500px)': {
                    bottom: 0,
                  },
                }}
              >
                <CustomInput
                  id={'closing-date'}
                  label={'Closing Date'}
                  type={'text'}
                  value={closingResDate}
                  onFocus={onFocus}
                  error={errors.closingDate}
                  rest={{
                    ...register('closingDate'),
                    value: getValues('closingDate') === undefined ? '' : getValues('closingDate'),
                  }}
                  endAdornment={<CalendarMonthOutlinedIcon />}
                />
                {focused && (
                  <Box sx={{ position: 'absolute', zIndex: 1, top: '50px' }}>
                    <DatePicker
                      selected={closingDate}
                      onChange={(date) => handleClosingDate(date)}
                      onSelect={(date) => handleClosingDate(date)}
                      inline
                      shouldCloseOnSelect={true}
                      onClickOutside={handleBlur}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={halfRowContainer}>
              <CustomSelect
                control={control}
                rest={{
                  ...register('financialAccepted'),
                }}
                label={'Financial Accepted'}
                options={dataAllProperties?.financialAccepted}
                isMulti={true}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenBrochure, openBrochure)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Brochure</Typography>
            {isMobile && (openBrochure ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openBrochure}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <Box sx={{ position: 'relative' }}>
              <CustomFileUpload
                file={file}
                onDrop={onDrop}
                fileFromBack={fileFromBack}
                isMulti={false}
                maxSize={10}
                extension={'pdf'}
                error={brochureError}
              />
              {(file || fileFromBack) && (
                <IconButton
                  onClick={handleClearFile}
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    bgcolor: 'white',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #E0E0E0',
                    '&:hover': {
                      bgcolor: '#F5F5F5',
                    },
                    zIndex: 1,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            data-isdraft="true"
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingDraft || isLoadingContinue}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default MarketingDescription;
