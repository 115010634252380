import React from 'react';
import { Box, Button, CircularProgress, Collapse, Typography } from '@mui/material';
import { black2 } from 'app/components/ThemeProvider/colors';
import CustomInput from 'app/components/CustomInput';
import CustomSelect from 'app/components/CustomSelect';
import usePropertyInformation from './usePropertyInformation';
import CustomSwitcherCheckbox from 'app/components/CustomSwitcherCheckbox';
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  btnSectionBox,
  container,
  draftBtn,
  halfRowContainer,
  loaderBox,
  rowContainer,
  saveBtn,
  sectionBox,
} from './PropertyInformation.sx';

const PropertyInformation = () => {
  const {
    control,
    register,
    role,
    onPlaceChanged,
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    onSubmit,
    getValues,
    dataAllProperties,
    isLoadingGetAddListingData,
    switchValue,
    handleChangeSwitch,
    handleChangeLotSize,
    lotSizeValue,
    openInfo,
    openAddress,
    openDetail,
    setOpenAddress,
    setOpenInfo,
    setOpenDetail,
    handleClick,
    isMobile,
    errors,
  } = usePropertyInformation();

  if (isLoadingAllProperties) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  if (isLoadingGetAddListingData) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={container}>
      <form onSubmit={onSubmit} data-isdraft="false">
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenInfo, openInfo)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Information</Typography>
            {isMobile && (openInfo ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openInfo}>
            <CustomInput
              id={'property-name'}
              label={'Property Name'}
              type={'text'}
              rest={{
                ...register('propertyName', {
                  value: getValues('propertyName') === undefined ? '' : getValues('propertyName'),
                }),
              }}
              error={errors.propertyName}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenAddress, openAddress)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Address Details</Typography>
            {isMobile && (openAddress ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openAddress}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <Autocomplete
              id="full-address"
              label="Property Full Address"
              onPlaceSelect={onPlaceChanged}
              error={errors.fullAddress}
              rest={{
                ...register('fullAddress', {
                  value: getValues('fullAddress') === undefined ? '' : getValues('fullAddress'),
                }),
              }}
            />
            <Box sx={rowContainer}>
              <CustomInput
                id={'street'}
                label={'Street'}
                type={'text'}
                disabled={true}
                rest={{ ...register('street') }}
                value={getValues('street') === undefined ? '' : getValues('street')}
              />
              <CustomInput
                id={'city'}
                label={'City'}
                type={'text'}
                disabled={true}
                rest={{ ...register('city') }}
                value={getValues('city') === undefined ? '' : getValues('city')}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'state'}
                label={'State'}
                type={'text'}
                disabled={true}
                rest={{ ...register('state') }}
                value={getValues('state') === undefined ? '' : getValues('state')}
              />
              <CustomInput
                id={'zipCode'}
                label={'ZIP Code'}
                type={'text'}
                disabled={true}
                rest={{ ...register('zipCode') }}
                value={getValues('zipCode') === undefined ? '' : getValues('zipCode')}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'country'}
                label={'Country'}
                type={'text'}
                disabled={true}
                rest={{ ...register('country') }}
                value={getValues('country') === undefined ? '' : getValues('country')}
              />
              <CustomInput
                id={'county'}
                label={'County'}
                type={'text'}
                disabled={true}
                rest={{ ...register('county') }}
                value={getValues('county') === undefined ? '' : getValues('county')}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDetail, openDetail)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Detail</Typography>
            {isMobile && (openDetail ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openDetail}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <Box sx={rowContainer}>
              <CustomSelect
                control={control}
                rest={{
                  ...register('propertyType'),
                }}
                label={'Property Type'}
                options={dataAllProperties?.type}
                error={errors.propertyType}
              />
              {role === 'commercial' ? (
                <CustomInput
                  id={'usable-sqft'}
                  label={'Usable Sq FT'}
                  type={'text'}
                  rest={{
                    ...register('usableSqFT', {
                      value: getValues('usableSqFT') === undefined ? '' : getValues('usableSqFT'),
                    }),
                  }}
                  error={errors.usableSqFT}
                />
              ) : (
                <CustomSelect
                  rest={{
                    ...register('occupancy'),
                  }}
                  label={'Occupancy'}
                  options={dataAllProperties?.ocuppancy}
                  control={control}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'total-sqft'}
                label={'Total Sq FT'}
                type={'text'}
                error={errors.totalSqFt}
                rest={{
                  ...register('totalSqFt', {
                    value: getValues('totalSqFt') === undefined ? '' : getValues('totalSqFt'),
                  }),
                }}
              />
              {role === 'commercial' ? (
                <CustomInput
                  id={'units'}
                  label={'Units'}
                  type={'text'}
                  rest={{
                    ...register('units', { value: getValues('units') === undefined ? '' : getValues('units') }),
                  }}
                  error={errors.units}
                />
              ) : (
                <CustomInput
                  id={'bathroom'}
                  label={'Bathroom'}
                  type={'text'}
                  rest={{
                    ...register('bathroom', {
                      value: getValues('bathroom') === undefined ? '' : getValues('bathroom'),
                    }),
                  }}
                  error={errors.bathroom}
                />
              )}
            </Box>
            {role === 'residential' && (
              <Box sx={rowContainer}>
                <CustomInput
                  id={'bedroom'}
                  label={'Bedroom'}
                  type={'number'}
                  rest={{
                    ...register('bedroom', { value: getValues('bedroom') === undefined ? '' : getValues('bedroom') }),
                  }}
                  error={errors.bedroom}
                />
                <CustomInput
                  id={'yearBuilt'}
                  label={'Year Built'}
                  type={'text'}
                  rest={{
                    ...register('yearBuilt', {
                      value: getValues('yearBuilt') === undefined ? '' : getValues('yearBuilt'),
                    }),
                  }}
                  error={errors.yearBuilt}
                />
              </Box>
            )}
            <Box sx={rowContainer}>
              <CustomInput
                id={'lot-size'}
                label={'Lot Size'}
                type={'text'}
                rest={{
                  ...register('lotSize', {
                    value: getValues('lotSize') === undefined ? lotSizeValue : getValues('lotSize'),
                    onChange: (e: any) => handleChangeLotSize(e),
                  }),
                }}
                endAdornment={<CustomSwitcherCheckbox value={switchValue} changeValue={handleChangeSwitch} />}
                pr="5px"
                value={lotSizeValue}
                error={errors.lotSize}
              />
              {role === 'commercial' ? (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('buildingClass'),
                  }}
                  label={'Building Class'}
                  options={dataAllProperties?.buildingClass}
                />
              ) : (
                <CustomSelect
                  label={'Parking'}
                  control={control}
                  rest={{
                    ...register('parking'),
                  }}
                  options={dataAllProperties?.parking}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'stories'}
                  label={'Stories'}
                  type={'text'}
                  rest={{
                    ...register('stories', { value: getValues('stories') === undefined ? '' : getValues('stories') }),
                  }}
                  error={errors.stories}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('style'),
                  }}
                  label={'Style'}
                  options={dataAllProperties?.style}
                />
              )}
              {role === 'commercial' ? (
                <CustomInput
                  id={'parking-spaces'}
                  label={'Parking Spaces'}
                  type={'text'}
                  rest={{
                    ...register('parkingSpaces', {
                      value: getValues('parkingSpaces') === undefined ? '' : getValues('parkingSpaces'),
                    }),
                  }}
                  error={errors.parkingSpaces}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('heating'),
                  }}
                  label={'Heating'}
                  options={dataAllProperties?.heating}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'zoning'}
                  label={'Zoning'}
                  type={'text'}
                  rest={{
                    ...register('zoning', {
                      value: getValues('zoning') === undefined ? '' : getValues('zoning'),
                    }),
                  }}
                  error={errors.zoning}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('roofing'),
                  }}
                  label={'Roofing'}
                  options={dataAllProperties?.roofing}
                />
              )}
              {role === 'commercial' ? (
                <CustomInput
                  id={'year-renovated'}
                  label={'Year Renovated'}
                  type={'text'}
                  rest={{
                    ...register('yearRenovated', {
                      value: getValues('yearRenovated') === undefined ? '' : getValues('yearRenovated'),
                    }),
                  }}
                  error={errors.yearRenovated}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('cooling'),
                  }}
                  label={'Cooling'}
                  options={dataAllProperties?.cooling}
                />
              )}
            </Box>
            <Box sx={role === 'commercial' ? halfRowContainer : rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'year-built'}
                  label={'Year Built'}
                  type={'text'}
                  rest={{
                    ...register('yearBuilt', {
                      value: getValues('yearBuilt') === undefined ? '' : getValues('yearBuilt'),
                    }),
                  }}
                  error={errors.yearBuilt}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('construction'),
                  }}
                  label={'Construction'}
                  options={dataAllProperties?.construction}
                />
              )}
              {role === 'commercial' ? null : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('foundation'),
                  }}
                  label={'Foundation'}
                  options={dataAllProperties?.foundation}
                />
              )}
            </Box>
            <Box sx={halfRowContainer}>
              {role === 'commercial' ? null : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('basement'),
                  }}
                  label={'Basement'}
                  options={dataAllProperties?.basement}
                />
              )}
            </Box>
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
            data-isdraft="true"
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PropertyInformation;
