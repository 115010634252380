// Enum for property status
export enum PropertyStatus {
  NONE = 1,
  DRAFT = 2,
  PUBLISHED = 3,
  EDITED = 4,
  VERIFIED = 5,
}

// Enum for property type
export enum PropertyType {
  RESIDENTIAL = 1,
  COMMERCIAL = 2,
  INDUSTRIAL = 3,
}

// Interface for single property
export interface Property {
  id: string | number;
  name: string;
  address: string;
  status: PropertyStatus;
  type: PropertyType;
  price: number;
  image: string;
}

export const getPropertyTypeName = (type: PropertyType): string => {
  switch (type) {
    case PropertyType.RESIDENTIAL:
      return 'Residential';
    case PropertyType.COMMERCIAL:
      return 'Commercial';
    case PropertyType.INDUSTRIAL:
      return 'Industrial';
    default:
      return '';
  }
};

// Enum for activity types
export enum ActivityType {
  VIEW_PROPERTY = 1,
  VIEW_DOCUMENT = 2,
  REQUEST_DOCUMENT = 3,
  OPEN_FLYER = 4,
  PRINT_PAGE = 5,
  CLICK_PHONE_NUMBER = 6,
  SAVED_PROPERTY = 7,
  INFORMATION_REQUEST = 8,
}

export interface Client {
  phoneNumber: string;
  id: number;
  name: string;
  email: string;
  status: ActivityStatus;
  title: string;
  note: string;
}

// Enum for order by type
enum OrderBy {
  ASC = 0,
  DESC = 1,
}

// Enum for activity status
export enum ActivityStatus {
  NONE = 1,
  PENDING = 2,
  ACCEPTED = 3,
  CANCELED = 4,
}

export interface ActivityFilter {
  pageNumber: number;
  pageSize: number;
  orderByParameter?: string;
  orderByType?: OrderBy;
  searchFilter?: string;
  type?: ActivityType;
  status?: ActivityStatus;
}

export interface Address {
  fullAddress: string;
  state: string;
  country: string;
  county: string;
  city: string;
  street: string | null;
  postalCode: string | null;
  longitude: number;
  latitude: number;
}

export interface UserInfo {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  imageUrl: string;
}

export interface PropertyInfo {
  id: string;
  name: string;
  imageUrl: string;
  address: Address;
}

export interface ActivityLog {
  id: number;
  createdTime: string;
  type: 'View Property';
  userInfo: UserInfo;
  propertyInfo: PropertyInfo;
  requestInfo: RequestInfo;
  signDocument: any | null;
}

export interface RequestInfo {
  fullName: string;
  email: string;
  phoneNumber: string;
  title: string;
  note: string;
}
