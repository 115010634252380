import React, { useState } from 'react';
import { Popover, Box, styled, Button } from '@mui/material';
import { PropertyStatus } from '../core/_models';

const StatusButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'none',
  padding: '6px 16px',
  backgroundColor: '#21364B',
  borderRadius: '20px',
  minWidth: '120px',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#21364B',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '8px',
    marginRight: '-4px',
    '& span': {
      fontSize: '18px',
      transform: 'rotate(90deg)',
      display: 'inline-block',
    },
  },
}));

const DropdownContainer = styled(Box)({
  padding: '4px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  width: '140px',
  border: '1px solid #E5E7EB',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
});

const StatusOption = styled(Button)({
  width: '100%',
  justifyContent: 'center',
  padding: '8px 16px',
  textTransform: 'none',
  color: '#374151',
  backgroundColor: '#fff',
  borderRadius: '8px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#F3F4F6',
  },
  '&:not(:last-child)': {
    marginBottom: '2px',
  },
});

const getStatusDisplayText = (status: PropertyStatus): string => {
  switch (status) {
    case PropertyStatus.DRAFT:
      return 'Draft';
    case PropertyStatus.AVAILABLE:
      return 'Available';
    case PropertyStatus.PENDING:
      return 'Pending';
    case PropertyStatus.CANCEL:
      return 'Cancelled';
    case PropertyStatus.CLOSED:
      return 'Closed';
    default:
      return 'Select Status';
  }
};

interface StatusDropdownProps {
  currentStatus: PropertyStatus;
  onAction: (action: PropertyStatus) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({ currentStatus, onAction }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: PropertyStatus) => {
    onAction(action);
    handleClose();
  };

  const open = Boolean(anchorEl);

  // Filter out PropertyStatus.NONE and currentStatus
  const statusOptions = Object.values(PropertyStatus)
    .filter((value): value is PropertyStatus =>
      typeof value === 'number' &&
      value !== PropertyStatus.NONE &&
      value !== currentStatus
    );

  return (
    <>
      <StatusButton
        endIcon={<span>›</span>}
        onClick={handleClick}
        aria-controls={open ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {getStatusDisplayText(currentStatus)}
      </StatusButton>
      <Popover
        id="status-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 0.5,
              overflow: 'visible',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                borderLeft: '1px solid #E5E7EB',
                borderTop: '1px solid #E5E7EB',
              },
            },
          },
        }}
      >
        <DropdownContainer>
          {statusOptions.map((status) => (
            <StatusOption
              key={status}
              onClick={() => handleAction(status)}
            >
              {getStatusDisplayText(status)}
            </StatusOption>
          ))}
        </DropdownContainer>
      </Popover>
    </>
  );
};

export default StatusDropdown;
