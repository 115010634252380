import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERIES } from '../../../../constants/consts';
import { getImageUrl, getMyListings, getMyListingsFilter, updatePropertyStatus } from '../core/_requests';
import defaultImage from 'assets/default-image.png';
import { getPropertyTypeName, Property, PropertyStatus } from '../core/_models';
import { EditButton, ListingImage, StyledCard } from './ny-listings.sx';
import { useNavigate } from 'react-router-dom';
import { stringifyRequestQuery } from '../../../../constants/helpers';
import { Response } from '../../../../constants/models';
import StatusDropdown from './listing-status-dropdown';

interface MyListingsProps {
  searchQuery?: string;
}

const MyListings = ({ searchQuery }: MyListingsProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: listings, error, isLoading } = useQuery(`${QUERIES.MYPROPERTY_LIST}-listings`, getMyListings);

  const { data: filteredData, isLoading: isFiltering } = useQuery<Response<Property[]>>(
    [`${QUERIES.MYPROPERTY_LIST}-listings-filter`, searchQuery],
    () => getMyListingsFilter(stringifyRequestQuery({ SearchFilter: searchQuery })),
    { enabled: !!searchQuery?.trim() }
  );


  const updateStatusMutation = useMutation(
    ({ id, status }: { id: string; status: PropertyStatus }) =>
      updatePropertyStatus(id, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${QUERIES.MYPROPERTY_LIST}-listings`);
        queryClient.invalidateQueries(`${QUERIES.MYPROPERTY_LIST}-listings-filter`);
      },
    }
  );

  const handleActionChange = (listingId: string, action: PropertyStatus) => {
    console.log(`Updating listing ${listingId} with action: ${action}`);
    updateStatusMutation.mutate({ id: listingId, status: action });
  };

  const displayedListings = React.useMemo(() => {
    const query = searchQuery?.trim();
    return query ? filteredData?.values || [] : listings || [];
  }, [listings, filteredData, searchQuery]);

  if (isLoading || isFiltering) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={300}>
        <div
          style={{
            width: 32,
            height: 32,
            border: '2px solid #f3f3f3',
            borderTop: '2px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Error loading listings
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          My Listings
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {displayedListings.length > 0 ? (
          displayedListings.map((listing: Property) => (
            <StyledCard key={listing.id}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <ListingImage
                    src={getImageUrl(listing.image)}
                    alt={listing.name}
                    onError={(e) => {
                      e.currentTarget.src = defaultImage;
                    }}
                  />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                      <LocationOn sx={{ fontSize: 16, color: 'grey.500' }} />
                      <Typography variant="body2" color="text.primary">
                        {displayAddress(listing)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        {getPropertyTypeName(listing.type)}
                      </Typography>
                      <Typography variant="body2">${Number(listing.price).toLocaleString()}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                  <StatusDropdown
                    currentStatus={listing.status}
                    onAction={(action) => handleActionChange(listing.id, action)}
                  />
                  <EditButton
                    variant="outlined"
                    onClick={() =>
                      navigate(
                        `/add-listing/${listing.type === 1 ? 'commercial' : 'residential'}/property-information/${
                          listing.id
                        }`
                      )
                    }
                  >
                    Edit
                  </EditButton>
                </Box>
              </Box>
            </StyledCard>
          ))
        ) : (
          <Typography color="text.secondary" textAlign="center">
            No listings found matching &quot;{searchQuery}&quot;
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MyListings;

const displayAddress = (listing: Property): string => {
  if (typeof listing.address === 'string') {
    return listing.address;
  }
  return listing.address.fullAddress;
};
